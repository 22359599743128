import React, { useEffect, useState } from "react";
import Router, { useRouter } from "next/router";
import App, { AppContext as NextAppContext, AppInitialProps, AppProps } from "next/app";
import NProgress from "nprogress";
import { ApolloProvider } from "@apollo/client";
import graphqlClient from "services/graphql/client";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { UserContext } from "context/user";
import { useUserProvider } from "context/hooks/useUserProvider";
import { StyleSheetManager } from "styled-components";
import { shouldForwardProp } from "@pepdirect/v3/helpers/transientProps";

import "styles/index.scss";

/* Default Spinner */
import "styles/spinner.scss";

import { NavHeader } from "components/Header";
import Navigation from "components/Navigation";

// import styles from reusable components here
import "styles/stylingOverrides.scss";
import "antd/dist/antd.css";

import {
  isPublicRoute,
  isServerSide,
  serverSideRedirect,
  setBasePathWithAppId,
} from "helpers/routing";
import axiosInstance, { createSsrAxiosInstance } from "services/api";
import absoluteUrl from "next-absolute-url";
import { status } from "services/auth";
import { isLoggedIn } from "helpers/user";
import { User } from "types/user";

// Init progress bar
/* Importing and customizing nprogress */
import "styles/nprogress.scss";

import { addGitShaToWindow } from "@pepdirect/helpers";
import { AxiosInstance as AxiosInstanceType } from "axios";

addGitShaToWindow();

NProgress.configure({ showSpinner: false });
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

// Scroll to top after router.push();
Router.events.on("routeChangeComplete", () => window?.scrollTo(0, 0));

interface AdminAppProps extends AppProps {
  user?: User;
}

interface AdminInitialProps extends AppInitialProps {
  user?: User;
}

AdminApp.getInitialProps = async (appContext: NextAppContext): Promise<AdminInitialProps> => {
  const appProps = await App.getInitialProps(appContext);
  let customAxiosInstance: AxiosInstanceType;

  const { appId } = appContext.router.query;
  if (isServerSide(appContext)) {
    customAxiosInstance = createSsrAxiosInstance(appContext?.ctx?.req, (appId as string) || "");
  } else {
    customAxiosInstance = axiosInstance;
  }

  try {
    const { origin } = absoluteUrl(appContext.ctx.req);
    const { data: user } = await status(origin, customAxiosInstance);

    if (isServerSide(appContext)) {
      if (!isLoggedIn(user) && !isPublicRoute(appContext.router.pathname)) {
        serverSideRedirect(appContext, "/sign-in");
      }
    }

    return { ...appProps, user };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error?.response?.status === 401) {
      if (isServerSide(appContext)) {
        if (!isPublicRoute(appContext.router.pathname)) {
          serverSideRedirect(appContext, "/sign-in");
        }
      }
      return { ...appProps, user: undefined };
    }
    throw error;
  }
};

// Revisit this later if layout needs to change https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
export default function AdminApp({ Component, pageProps, user }: AdminAppProps): JSX.Element {
  const { userContextValue } = useUserProvider(user);
  const [didInitAxiosInterceptor, setDidInitAxiosInterceptor] = useState<boolean>(false);

  const router = useRouter();
  const { appId } = router.query;

  setBasePathWithAppId(axiosInstance, appId);
  useEffect(() => {
    /* Set base path for client-side axios instance */
    setBasePathWithAppId(axiosInstance, appId);
  }, [appId]);

  // Client-side 401 interceptor
  useEffect(() => {
    if (!didInitAxiosInterceptor) {
      axiosInstance.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response?.status === 401) {
            // TODO: maybe show error message instead of instant redirect?
            userContextValue.logout();
          }
          return Promise.reject(error);
        }
      );
      setDidInitAxiosInterceptor(true);
    }
  }, [didInitAxiosInterceptor, setDidInitAxiosInterceptor, router, userContextValue]);

  return (
    <ApolloProvider client={graphqlClient}>
      <UserContext.Provider value={userContextValue}>
        <DndProvider backend={HTML5Backend}>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <div className="page-layout">
              <NavHeader />
              <div className="page-main-with-nav">
                {appId && <Navigation appId={appId as string} />}
                <main className="page-wrapper">
                  <Component {...pageProps} />
                </main>
              </div>
            </div>
          </StyleSheetManager>
        </DndProvider>
      </UserContext.Provider>
    </ApolloProvider>
  );
}
