import isPropValid from "@emotion/is-prop-valid";

/**
 * Takes an existing type and prefixes the passed-in props with $ to make them transient
 *
 * @example
 * interface MyElementProps {
 *   color?: string;
 *   size?: string;
 *   className?: string;
 * }
 *
 * type MyElementWithTransientProps = WithTransientProps<MyElementProps, "color" | "size">;
 *
 * becomes:
 * interface MyElementWithTransientProps {
 *   $color?: string;
 *   $size?: string;
 *   className?: string;
 * }
 *
 */
export type WithTransientProps<Props, Keys extends keyof Props> = Omit<
  Props,
  Keys
> & {
  [P in Keys as `$${string & P}`]: Props[P];
};

// https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default
export const shouldForwardProp = (
  propName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: string | HTMLElement | React.ComponentType<any>
): boolean => (typeof target === "string" ? isPropValid(propName) : true);
